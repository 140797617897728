<template>
    <div class="about">
        <h2>Contato</h2>
    </div>
</template>

<script>
export default {
    name: 'Contato',
    methods: {
    },
}
</script>