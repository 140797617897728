<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <ParticlesBG mgs="test" /> 
    <section id="page-content">
      <a id="sobre"></a>
      <div style="display: none;">
        <About />
        <a id="contato"></a>
        <Contato />
      </div>
    </section>
    <BodyFooter />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import ParticlesBG from '@/components/ParticlesBG.vue'
import About from '@/components/About.vue';
import Contato from '@/components/Contato.vue';
import BodyFooter from '@/components/BodyFooter.vue';

export default {
  name: 'HomeView',
  components: {
    // HelloWorld,
    ParticlesBG,
    About,
    Contato,
    BodyFooter
  }
}
</script>

<style scoped>
#page-content {
  position: absolute;
  top: 40rem;
  border: 1px sold red;
}
</style>
