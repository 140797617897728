<template>
  <nav>
    <router-link to="/" class="nav-brand">
      <span><img src="favicon.ico"> Aeonsoft</span></router-link> |    
    <!-- <router-link to="#sobre">Sobre</router-link> | 
    <router-link to="#contato">Contato</router-link> -->
    <!-- <a href="#sobre">Sobre</a> | -->
    <!-- <a href="#contato">Contato</a> -->
  </nav>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--brand-blue);  
  width: 100vw;
}

nav {   
  padding: .7rem;    
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  display: inline-block;
  top: 0rem;
  padding-top: 1rem;
  margin: 0 auto;
  /* float: left; */
  z-index: 1;
  background-color: var(--brand-black);
  /* background: linear-gradient(var(--brand-blue), var(--brand-black)); */
  /* border: 1px solid rgba(255,255,255, .2); */
  border-top: none;
  border-radius: 0  0 .5rem .5rem;
}

nav a {
  color: white;
  font-weight: bold;
  text-decoration: none;
  opacity: .7;

  &:hover{
    opacity: 1;    
  }
}

nav a.router-link-exact-active {
  font-weight: bold;  
  opacity: 1;
}

.nav-brand{
  span {    
    align-content: center;
    align-items: center;
  }

  img {
    height: 1rem;
    margin-right: .3rem;
    float: left;
  }
}
</style>
