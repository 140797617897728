<template>
    <footer>
        <strong>Aeonsoft</strong><sup>©</sup> 2011-{{ currentYear }}, todos os direitos reservados.
    </footer>
</template>

<script>
export default {
    name: 'BodyFooter',
    data() {
        return {
            currentYear: new Date().getFullYear()
        }
    },
    methods: {
    },
}
</script>

<style scoped>
footer {    
    color: white;
    border-top: .1rem solid gray;
    min-height: 5rem;
    padding: 2rem;
    opacity: .4;        
}
</style>