<template>
    <div id="particles-js">
        <div class="hero">
            <content>
                <img src="../assets/logo.png">
                <h1>Aeonsoft<span class="roboto-thin">Tecnologia</span></h1>
            </content>
        </div>
    </div>
    <div id="particles-separator">????</div>
</template>

<script>
export default {
    name: 'ParticlesBG',
    props: {
        msg: String
    },

    methods: {
    },

    mounted() {
        console.log('setup')
        particlesJS.load('particles-js', 'assets/particles.json', function () {
            console.log('callback - particles.js config loaded');
        })
    }
}
</script>

<style>
:root {
    --hero-height: 100px;
    --particles-hight: 50rem;
}
</style>
<style scoped>
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, 0) scale(1.03);
    }

    to {
        opacity: 1;
        transform: translate(-50%, 0) scale(1);
    }
}

#particles-js {
    position: absolute;
    width: 100vw;
    height: var(--particles-hight);
    background-color: #02021a;
    background: linear-gradient(#020213, var(--brand-blue));
}

#particles-separator {
    min-height: var(--particles-hight);
}


.hero {
    position: absolute;
    top: calc((var(--particles-hight)/2) - (var(--hero-height)));
    left: 50%;
    transform: translate(-50%, 0);
    animation: fadeIn 0.75s ease-in;

    content {
        display: flex;
        align-items: center;
        width: auto;

        img {
            height: calc(var(--hero-height) * 1.3);
            margin: 0;
            padding: 0;
        }

        h1 {
            margin: 0;
            padding: 0;
            display: inline;

            font-family: "Sora", sans-serif;
            font-optical-sizing: auto;
            font-weight: bold;
            font-style: normal;
            font-size: 72px;
            color: white;

            span {
                opacity: .4;
            }
        }
    }
}

@media screen and ((max-width : 320px) or (max-width : 600px) or (max-width : 992px)) {
    .hero {
        content {
            display: inline-block;

            img {
                font-size: 14vw;
                height: 22vw;
            }

            h1 {
                font-size: 10vw;

                span {
                    display: inline-block;
                }
            }
        }

    }
}
</style>
