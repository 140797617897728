<template>
    <div class="about">
        <h2>Quem Somos</h2>
        <p>Na Aeonsoft, somos apaixonados por inovação e tecnologia. Desde 2011, temos ajudado empresas de diferentes
            setores a transformar suas ideias em realidade, por meio do desenvolvimento de sistemas personalizados e
            serviços de consultoria tecnológica. Com mais de 10 anos de experiência no mercado, contamos com uma equipe
            de engenheiros especializados que trazem a expertise necessária para resolver problemas complexos de forma
            eficiente.</p>
        <p>Nossa trajetória inclui parcerias de sucesso com grandes empresas, como Exxon Mobil, Unisys e a Secretaria da
            Fazenda do Estado de São Paulo, o que reforça nossa capacidade de entregar soluções robustas e de confiança.
            Atuamos de maneira próxima e personalizada, garantindo que cada projeto seja desenhado para atender às
            necessidades específicas de nossos clientes.</p>
        <p>Na Aeonsoft, acreditamos que o sucesso dos nossos clientes é o nosso sucesso. Por isso, estamos sempre em
            busca de novas tecnologias e metodologias para garantir resultados inovadores e de alta qualidade. Queremos
            ser mais que um fornecedor — queremos ser o parceiro estratégico que ajuda sua empresa a crescer e se
            destacar no mercado.</p>
    </div>
</template>

<script>
export default {
    name: 'About',
    methods: {
    },
}
</script>